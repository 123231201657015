<template>
  <div
    class="single-answer-question"
    role="radiogroup"
    data-question
    tabindex="-1"
    :aria-label="$t(title)"
    @keydown.down.prevent="onKeyboardNavigation('down')"
    @keydown.up.prevent="onKeyboardNavigation('up')"
  >
    <div v-if="image" class="flex--center--center q-mt8 q-mb32">
      <img :src="image" :alt="imageAlt" />
    </div>
    <question-title class="q-mb16 q-mt0" :hint="hint">{{ $t(title) }}</question-title>
    <div class="options flex flex-wrap--wrap" :class="optionsClasses">
      <div
        v-for="option of options"
        :key="option.value"
        class="options__option q-mb8 full-width flex flex-column flex--align-center"
      >
        <img v-if="option.image" class="q-mb16" :src="option.image" :alt="option.image" />
        <q-radio-button
          ref="option"
          :tabindex="getOptionTabIndex(option)"
          :checked="option.value === value"
          :ai-selected="option.aiSelected"
          :hint="option.hint"
          :dysfunction="dysfunction"
          @change="onInput(option.value)"
          @mouseover="$emit('option-mouseover', option.value)"
          @mouseleave="$emit('option-mouseleave')"
        >
          <span class="options__question-value">{{ $t(option.text) }}</span>
        </q-radio-button>
      </div>
    </div>
  </div>
</template>

<script>
import { clamp } from 'ramda';

import QRadioButton from '@/modules/questionnaire/new-design-components/inputs/QRadioButton';
import QuestionTitle from '@/modules/questionnaire/new-design-components/questions/QuestionTitle';

export default {
  name: 'SingleAnswerQuestion',
  components: { QRadioButton, QuestionTitle },
  props: {
    title: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      required: true
    },
    twoColumns: {
      type: Boolean,
      default: false
    },
    yesNo: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    dysfunction: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    imageAlt: {
      type: String,
      default: 'Question image'
    }
  },
  computed: {
    optionsClasses() {
      return {
        'options--two-columns': this.twoColumns,
        'options--yes-no': this.yesNo
      };
    },
    selectedOptionIndex() {
      return this.options.findIndex(option => option.value === this.value);
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onKeyboardNavigation(direction) {
      const increment = direction === 'down' ? 1 : -1;
      const index = clamp(0, this.options.length - 1, this.selectedOptionIndex + increment);

      this.$refs.option[index].$el.focus();
    },
    getOptionTabIndex(option) {
      if (!this.value) {
        return '0';
      }

      return option.value === this.value ? '0' : '-1';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';
@import '~universkin-shared/src/assets/styles/scss/colors.scss';

.single-answer-question {
  width: 100%;
}

.options {
  &--yes-no {
    .options__option {
      flex: 0 1 calc(50% - 16px);

      &:nth-child(2n) {
        margin-left: 32px;
      }
    }
  }
}

@media (min-width: $desktop-start) {
  .options {
    &--two-columns {
      .options__option {
        flex: 0 1 calc(50% - 16px);

        &:nth-child(2n) {
          margin-left: 32px;
        }
      }
    }
  }
}
</style>
