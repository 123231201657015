<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['drynessCausedByClimate'])">
      <single-answer-question
        :title="title"
        :value="drynessCausedByClimate"
        :options="$options.drynessCausesOptions"
        dysfunction="skin-dryness"
        @input="onFieldChange('drynessCausedByClimate', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { DRYNESS_CAUSES_OPTIONS } from '@/modules/questionnaire/constants/steps/skinDryness';

export default {
  name: 'EnvironmentTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  drynessCausesOptions: DRYNESS_CAUSES_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    drynessCausedByClimate: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    }
  }
};
</script>
