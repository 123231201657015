import { BOOLEAN, DRYNESS_OCCURRENCE, FACIAL_DRYNESS } from '@/modules/questionnaire/api/constants';

export const DRYNESS_LOCATIONS_OPTIONS = [
  { value: FACIAL_DRYNESS.CHEEKS, text: 'facialDrynessLocation.cheeks' },
  { value: FACIAL_DRYNESS.CHEEKS_AND_TZONE, text: 'facialDrynessLocation.cheeksAndTzone' }
];

export const DRYNESS_CAUSES_OPTIONS = [
  { value: BOOLEAN.YES, text: 'drynessCausedByClimate.yes' },
  { value: BOOLEAN.NO, text: 'drynessCausedByClimate.no' }
];

export const DRYNESS_OCCURRENCE_OPTIONS = [
  { value: DRYNESS_OCCURRENCE.AFTER_CLEANSING, text: 'drynessOccurrence.afterCleansing' },
  { value: DRYNESS_OCCURRENCE.ALL_DAY, text: 'drynessOccurrence.allDay' }
];
